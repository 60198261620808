#UserAgent
_ua = do ->#{{{
  IE = document.uniqueID
  ltIE6 = typeof window.addEventListener is undefined and typeof document.documentElement.style.maxHeight is undefined
  ltIE7 = typeof window.addEventListener is undefined and typeof document.querySelectorAll is undefined
  ltIE8 = typeof window.addEventListener is undefined and typeof document.getElementsByClassName is undefined
  ltIE9 = IE and typeof window.Worker is undefined
  IE6 = IE and ltIE6
  IE7 = IE and ltIE7 and !ltIE6
  IE8 = IE and ltIE8 and !ltIE7 and !ltIE6
  IE9 = IE and ltIE9 and !ltIE8 and !ltIE7 and !ltIE6
  IE10 = IE and !ltIE9 and !ltIE8 and !ltIE7 and !ltIE6
  Webkit = !document.uniqueID and !window.opera and !window.sidebar and !window.orientation and window.localStorage
  Safari = Webkit and navigator.vendor.search(/apple/i) isnt -1
  Chrome = Webkit and navigator.vendor.search(/google/i) isnt -1

  return {
    IE:IE,
    ltIE6:ltIE6,
    ltIE7:ltIE7,
    ltIE8:ltIE8,
    ltIE9:ltIE9,
    IE6:IE6,
    IE7:IE7,
    IE8:IE8,
    IE9:IE9,
    IE10:IE10,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit:Webkit,
    Safari:Safari,
    Chrome:Chrome,
    Mobile:window.orientation
  }
#}}}

#URL
url = do ->#{{{
  href = location.href.split '/'

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  workRegex = /^.*\/pc\/[^\/]+\/.*$/

  for val , i in href
    if val == '' or i == href.length - 1 and val.indexOf '.'
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    #length = 3
    length = 4

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j == 0
      path += '//'

    else
      path += '/'

  return path
#}}}
#
#スムーススクロール関数モーション定義
jQuery.extend(#{{{
  jQuery.easing,
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
)
#}}}

$ ->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

  #フッター高さ取得
  footerHeight = # {{{
    ->
      add = 0
      addFooter = $('#troble-evocation').outerHeight() + $('#footer .banner').outerHeight()
      height = $('#footer').outerHeight()
      $('#content').css('padding-bottom',height+addFooter)
      $('#footer').css('height',height+addFooter)
      return

  $(window).on('resize load'
    ->
      footerHeight()
      return
  )
  # }}}

  # アンカーリンク スムーススクロール
  $('a[href^=#]').click(#{{{
    (e)->
      $(
        if (navigator.userAgent.indexOf('Opera') isnt -1)
        then (
          if document.compatMode is 'BackCompat'
          then 'body'
          else 'html'
        )
        else 'html,body'
      ).animate(
        #scrollTop:$($(this).attr('href')).offset().top - $('#content.is-float .mask .bg').height()
        scrollTop:$($(this).attr('href')).offset().top - 129
      ,
        easing:'easeInOutCirc',
        duration:1000
      )

      e.preventDefault()
      return
  )#}}}

  # スクロール背景表示
  bgImage =#{{{
    (show,total)->
      if typeof total is 'undefined'
        bgTotalCount = 72
      else
        bgTotalCount = total

      bgTotalCountArray = []
      bgDefaultPositionY = []

      if typeof show is 'undefined'
        showCount = 10
      else
        showCount = show
      $container = $('#container')
      containerHeight = $container.height()

      for i in [0..bgTotalCount-1]
        bgTotalCountArray[i] = i

      shuffle =
        (array)->
          i = array.length

          while (i)
            j = Math.floor(Math.random() * i)
            t = array[--i]
            array[i] = array[j]
            array[j] = t
          return array

      shuffle(bgTotalCountArray)

      for i in [0..showCount-1]
        min = Math.floor((100 / showCount) * i)
        max = Math.floor((100 / showCount) * (i+1))
        posY = Math.floor(containerHeight / 100 * Math.floor(Math.random() * (max - min)+min))
        bgDefaultPositionY[i] = posY

        $container.append('<img class="no'+(i+1)+' bgimg" src="'+url+'img/scroll_bg'+(bgTotalCountArray[i]+1)+'.png" />')
        $('.bgimg.no'+(i+1)).css('left',Math.floor(Math.random() * 100)+'%')
        $('.bgimg.no'+(i+1)).css('top',posY)

      j = 1
      speed = 2

      $('.bgimg.no'+(j)).addClass('is-show')

      $(window).on('scroll',
        (e)->
          currentPos = $(this).scrollTop()

          $('.bgimg').each(
            (e)->
              $target = $(this)
              $target.css('top', bgDefaultPositionY[e] - (-1 * currentPos / speed))
          )

          if currentPos > (($(document).height() - $(window).height()) / showCount) * j
            $('.bgimg.no'+(j+1)).addClass('is-show')
            j++
      )#}}}

  floatPoint = 0
  additionalHeight = 0

  $(window).load(
    ->
      # ヘッダー フロート
      #{{{
      if _ua.Mobile isnt 0
        $header = $('#header')
        $menu = $('.top.m-menu')
        $title = $('.m-title')
        $logo = $('#logo')
        $logoImg = $logo.find('img')
        minWidth = 1020
        headerHeight = $header.outerHeight(true)
        menuHeight = $menu.outerHeight(true)
        titleHeight = $title.outerHeight(true)
        floatPoint = headerHeight + titleHeight + menuHeight
        additionalHeight = 100

        $('#content').prepend('<div class="mask"><div class="bg"></div></div>')

        if $(window).width() < minWidth
          $('body').css('background-position',((minWidth - 1250)/2)+'px top')
        else
          $('body').removeAttr('style')

        $(window).on('resize',
          ->
            if $(window).width() < minWidth
              $('body').css('background-position',((minWidth - 1250)/2)+'px top')
            else
              $('body').removeAttr('style')
        )

        $(window).on('scroll',
          ->
            if $(window).width() < minWidth
              $('body').css('background-position',($(this).scrollLeft() * -1 + ((minWidth - 1250)/2))+'px top')
              $('.mask').css('left',($(this).scrollLeft() * -1))
            else
              $('body,.mask').removeAttr('style')

            $('.mask .bg').css('background-position','right '+$(this).scrollTop() * -1+'px')

            if $(this).scrollTop() > floatPoint
              $header.children().css('left',$(this).scrollLeft() * -1)
              $menu.children().css('left',$(this).scrollLeft() * -1)

              if $header[0].className.indexOf('is-fixed') is -1
                $header.addClass('is-fixed')
                $('#content').addClass('is-fixed').css('padding-top',headerHeight)

                if $class is 'home'
                  $logoImg.attr('src',$logoImg.attr('src').replace('index_logo.gif','logo_f.gif'))
                else
                  $logoImg.attr('src',$logoImg.attr('src').replace('logo.gif','logo_f.gif'))

                if $menu[0]? and $menu[0].className.indexOf('is-fixed') is -1
                  $menu.addClass('is-fixed')
                  $('#content').addClass('is-fixed').css('padding-top',headerHeight + menuHeight)
                  $menu.find('img').each(
                    ->
                      $(this).attr('src',$(this).attr('src').replace(/(_a)?.png/,'_f$1.png'))
                  )

              if $(this).scrollTop() > floatPoint + additionalHeight
                $header.addClass('is-float')
                $menu.addClass('is-float')
                $('#content').addClass('is-float')
            else
              $header.children().css('left',0)
              $menu.children().css('left',0)

              if $header[0].className.indexOf('is-fixed') isnt -1
                $header.removeClass('is-fixed')
                $('#content').removeClass('is-fixed').css('padding-top',0)

                if $class is 'home'
                  $logoImg.attr('src',$logoImg.attr('src').replace('logo_f.gif','index_logo.gif'))
                else
                  $logoImg.attr('src',$logoImg.attr('src').replace('logo_f.gif','logo.gif'))

                if $menu[0]? and $menu[0].className.indexOf('is-fixed') isnt -1
                  $menu.removeClass('is-fixed')
                  $menu.find('img').each(
                    ->
                      $(this).attr('src',$(this).attr('src').replace(/_f(_a)?.png/,'$1.png'))
                  )

            if $(this).scrollTop() <= floatPoint + additionalHeight
              $header.removeClass('is-float')
              $menu.removeClass('is-float')
              $('#content').removeClass('is-float')
              $('.mask').hide()
        )#}}}

      $(window).on('scroll',
        ->
          #if $(this).scrollTop() > $('#container').height() * 0.6
          if $(this).scrollTop() > floatPoint
            $('.sd-form').addClass('is-active')
          else
            $('.sd-form').removeClass('is-active')
      )
  )

  addFooter = $('#troble-evocation').outerHeight()
  height = $('#footer').outerHeight()

  $('#footer .close').on('click',
    ->
      $(this).parents('.banner').fadeOut()
      $('#footer').css('height',height+addFooter)
      return
  )

  $(window).on('load'
    ->
      bgImage()

      $('.section .case-item').twentytwenty()

      $('.doctor-inner').slidesjs(
        width:435
        height:185
        navigation:
          active:true
          effect:"slide"
        play:
          effect:"slide"
          auto:true
          interval:10000
        pagination:
          active:false
      )
  )
